import { OrbitControls, Sphere } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useEffect, useRef } from 'react'

function Planet({texture, planetType, size, rotateRight, luminosity, lightingPos, lightingIntensity, meshOpacity, isTransparent, rotationalSpeed}) {
    const containerRef = useRef();
    useEffect(()=>{
        if (!containerRef.current) return;
        for (let i = 0; i <100; i++){
          containerRef.current.appendChild(genStar(Math.floor(Math.random()*4), [Math.floor(Math.random()*containerRef.current.clientHeight), Math.floor(Math.random()*containerRef.current.clientWidth)], Math.floor(Math.random()*50)+50));
        }
      }, [containerRef]);

  return (
    <div style={{overflow: "hidden", width: 356, height: 356, borderRadius: "25px"}}>
        <div style={{width: 356, height: 356, padding: "50px", background: "black", position: "relative"}} ref={containerRef}>
            <Canvas style={{boxShadow: `0px 0px ${luminosity*100}px rgb(${getShadowColor(luminosity)})`}} className='canvas'>
                <ambientLight intensity={luminosity}/>
                {planetType!="sun"&&<directionalLight position={lightingPos} intensity={lightingIntensity}/>}
                <SphereTexture isTransparent={isTransparent} opacity={meshOpacity} rotateRight={rotateRight} size={size} texture={texture} rotationalSpeed={rotationalSpeed}/>
                <OrbitControls enabled={false}/>
            </Canvas>
        </div>
    </div>
  )
}

function getShadowColor(luminosity){
    return `${luminosity*10}, ${luminosity/10}, ${luminosity/10}`;
}

const SphereTexture = ({size, texture, opacity, isTransparent, rotateRight, rotationalSpeed})=>{
    const sphereRef = useRef();

    useFrame(()=>{
        if (sphereRef.current){
        if (rotateRight){
            sphereRef.current.rotation.y += rotationalSpeed;
        }else{
            sphereRef.current.rotation.y -= rotationalSpeed;
        }}
    });

    return(
        <Sphere ref={sphereRef} args={[size, 128, 128]}>
            <meshPhongMaterial attach="material" map={texture} opacity={opacity} transparent={isTransparent}/>
        </Sphere>
    )
}

function genStar(radius, pos=[], opacity){
    const star = document.createElement("div");
    star.classList.add("star");
    star.style.width=`${radius}px`;
    star.style.height=`${radius}px`;
    star.style.top=`${pos[0]}px`;
    star.style.left=`${pos[1]}px`;
    star.style.opacity=`${opacity}%`;
    star.style.boxShadow = `0px 0px ${radius*1000}px white`;
    return star;
  }

export default Planet