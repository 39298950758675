import * as THREE from 'three';
import perlin from 'perlin-noise';

export function generatePlanetTexture(size = 512, planetType) {
  const noise = perlin.generatePerlinNoise(size, size, {
    octaveCount: 6,   // 6
    amplitude: 0.5,
    persistence: planetType=="sun"?1.5:0.5
  });

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  const imageData = context.createImageData(size, size);

  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      const value = noise[x + y * size];
      let color = getPlanetDefines(value, planetType);
      /*if (value < 0.5) {
        // Ocean
        color = [0, 0, Math.floor(value * 255)];
      } else if (value < 0.8) {
        // Land
        color = [Math.floor(value * 255), Math.floor(value * 255), 0];
      } else {
        // Mountains
        color = [Math.floor(value * 255), Math.floor(value * 255), Math.floor(value * 255)];
      }*/
      const cell = (x + y * size) * 4;
      imageData.data[cell] = color[0];        // R
      imageData.data[cell + 1] = color[1];    // G
      imageData.data[cell + 2] = color[2];    // B
      imageData.data[cell + 3] = color[3];         // A
    }
  }

  context.putImageData(imageData, 0, 0);

  const texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;

  return texture;
}

function getPlanetDefines(value, planet="earth"){
    switch(planet){
        case "earth":
        default:
            if (value<0.5){ // Ocean
                return [0, 0, Math.floor(value*180), 255];
            }else if (value<0.73){  // Land
                return [Math.floor(value * 128), Math.floor(value * 128), Math.floor(value*0), 255];
            }else{  // Hills
                return [Math.floor(value * 120), Math.floor(value * 120), Math.floor(value * 120), 255];
            }
            break;
        case "lava":
            if (value<0.7){
              return [Math.floor(value*255), Math.floor(value*0), Math.floor(value*0), 255];
            }else if (value <0.75){
              return [Math.floor(value*185), Math.floor(value*65), Math.floor(value*10), 255];
            }else {
              return [Math.floor(value*255), Math.floor(value*255), Math.floor(value*0), 255];
            }
            break;
        case "gas":
            return [Math.floor(value*150), Math.floor(value*255), Math.floor(value*255), Math.floor(value*50)];
            break;
        case "water":
            if (value<0.6){ // Ocean
            return [Math.floor(value*0), Math.floor(value*80), Math.floor(value*255), 255];
            }else{
              return [Math.floor(value*51), Math.floor(value*51), Math.floor(value*135), 255];
            }
            break;
        case "rock":
            if (value>0.3){
              return [Math.floor(value*150), Math.floor(value*150), Math.floor(value*150), 255];
            }else if (value>0.1){
              return [Math.floor(value*200), Math.floor(value*200), Math.floor(value*200), 255];
            }else{
              return [Math.floor(value*100), Math.floor(value*100), Math.floor(value*100), 255];
            }
            break;
        case "sun":
            if (value<0.8){
              return [Math.floor(value*185), Math.floor(value*40), Math.floor(value*10), 255];
            }else{
              return [Math.floor(value*255), Math.floor(value*0), Math.floor(value*0), 255];
            }
            break;
    }
}

