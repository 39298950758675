import React, { useEffect, useRef, useState } from 'react';
import RotatingPlanet from './RotatingPlanet';
import Planet from './Components/Planet';
import { generatePlanetTexture } from './textureGenerator';
import { Button, MenuItem, Paper, Select, Slider, ThemeProvider, createTheme } from '@mui/material';

const dark = createTheme({
  palette: {
    mode: "dark"
  }
})
function App() {

  function getLighting(){
    return [Math.floor(Math.random()*32), Math.floor(Math.random()*32), Math.floor(Math.random()*32)];
}
  const [luminosity, setLuminosity] = useState(10);
  const [texture, setTexture] = useState(generatePlanetTexture(512, "sun"));
  const [planetType, setPlanetType] = useState("sun");
  const [regen, setRegen] = useState();

  useEffect(()=>{
    setTexture(generatePlanetTexture(512, planetType));
    if (planetType!="sun") setLuminosity(1);
  }, [planetType, regen]);

  return (
    <ThemeProvider theme={dark}>
    <div className='conainer'>
        <Paper elevation={4} className='w-2/3 md:w-1/4 flex flex-col space-y-2 p-2 items-center justify-center'>
          <Select value={planetType} onChange={(e)=>{setPlanetType(e.target.value)}} className='w-fit'>
            <MenuItem value="earth">Erde</MenuItem>
            <MenuItem value="sun">Stern</MenuItem>
            <MenuItem value="rock">Stein</MenuItem>
            <MenuItem value="lava">Lava</MenuItem>
            <MenuItem value="water">Wasser</MenuItem>
            <MenuItem value="gas">Gas</MenuItem>
          </Select>
          <Button variant='outlined' onClick={()=>{setRegen(Math.random())}}>Textur Regenerieren</Button>
          {planetType=="sun"&&
          <>
          <p>Helligkeit:</p>
          <Slider value={luminosity} onChange={(e)=>{setLuminosity(e.target.value)}} max={1000} min={1} className='max-w-[70%]'/>
          </>}
        </Paper>
        <div style={{margin: "50px"}}>
          <Planet isTransparent={false} lightingIntensity={planetType!="sun"?10:1} lightingPos={getLighting()} luminosity={luminosity} meshOpacity={100} planetType={planetType} rotateRight={true} rotationalSpeed={0.0005} size={3} texture={texture}/>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
